import React, { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import * as faceapi from 'face-api.js';
import './CapturePage.css';
import { AppContext } from '../context/AppContext';
import Button from '@material-ui/core/Button';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    botaoCapturar: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: "11px",
            paddingBottom: "11px",
        },
    }
}));

const CapturePage = () => {
    const classes = useStyles();
    const webcamRef = useRef(null);
    const navigate = useNavigate();
    const { capturedImage, setCapturedImage, codAulaTeorica, codAluno } = useContext(AppContext);
    const [instructions, setInstructions] = useState('Vamos lá, prepare-se para começar.');
    const [maskBorderColor, setMaskBorderColor] = useState('transparent');

    useEffect(() => {
        ////console.log('Código da Aula Teórica recebido:', codAulaTeorica); // Verifica se o valor está sendo recebido
        ////console.log('Código do Aluno recebido:', codAluno); // Verifica se o valor está sendo recebido
        const loadModels = async () => {
            await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
            await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
        };
        loadModels();
    }, [codAulaTeorica, codAluno]);

    const handleFaceDetection = async () => {
        const video = webcamRef.current?.video;
        if (!video) return [];
        const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();
        return detections;
    };

    const isFaceWithinBounds = (landmarks) => {
        const video = webcamRef.current?.video;
        if (!video) return false;
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;

        const maskHeight = 0.8 * videoHeight;
        const maskWidth = (2 / 3) * maskHeight;
        const maskRadiusX = maskWidth / 2;
        const maskRadiusY = maskHeight / 2;

        const maskCenterX = videoWidth / 2;
        const maskCenterY = videoHeight / 2;

        const withinBounds = landmarks.positions.every(
            (point) =>
                Math.pow(point.x - maskCenterX, 2) / Math.pow(maskRadiusX, 2) +
                Math.pow(point.y - maskCenterY, 2) / Math.pow(maskRadiusY, 2) <= 1.5
        );

        if (withinBounds) {
            setMaskBorderColor('green');
            setInstructions('Rosto centralizado, captura permitida!\n\n Clique no botão "Capturar"');
        } else {
            setMaskBorderColor('red');
            setInstructions('Por favor, centralize seu rosto no centro da máscara!');
        }

        return withinBounds;
    };

    const checkFacePosition = async () => {
        const video = webcamRef.current?.video;
        if (!video) return;
        const detections = await faceapi.detectSingleFace(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();

        if (detections) {
            isFaceWithinBounds(detections.landmarks);
        } else {
            setMaskBorderColor('red');
            setInstructions('Por favor, encaixe seu rosto no centro da máscara.');
        }
    };

    useEffect(() => {
        const intervalId = setInterval(checkFacePosition, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const handleCapture = async () => {
        const video = webcamRef.current?.video;
        if (!video) return;
        const detections = await faceapi.detectSingleFace(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();

        if (detections && isFaceWithinBounds(detections.landmarks)) {
            const imageSrc = webcamRef.current.getScreenshot();
            setCapturedImage(imageSrc);
            setInstructions('Captura concluída. Clique para confirmar.');
            navigate('/envio');
        } else {
            setInstructions('Por favor, encaixe seu rosto no centro da máscara.');
        }
    };

    const handleRetake = () => {
        setCapturedImage(null);
        setInstructions('Vamos lá, prepare-se para começar.');
        setMaskBorderColor('transparent');
    };

    return (
        <div className='capture-container'>

            <h1 className={`instructions ${maskBorderColor}`}>{instructions}</h1>

            <div className='webcam-container' >
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    className="webcam"
                    mirrored={true}
                />
                <div className="mask" style={{ borderColor: maskBorderColor }} />
            </div>


            <Grid
                container
                justifyContent='center'
                style={{ maxWidth: "600px", gap: '1rem', zIndex: 10}} >
                <Grid item xs={10} sm={5}>
                    <Button
                        fullWidth
                        variant="contained"
                        size='large'
                        color="primary"
                        onClick={handleCapture}
                        className={classes.botaoCapturar}
                    >Capturar</Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default CapturePage;
