// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Button, Container, Typography, Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
// import Typing from 'react-typing-effect';
// import studentImage from './asset/image/icone4.png';
// import instructorImage from './asset/image/icone1.png';
// import companyLogo from './asset/image/logo512.png'; // Adicione o caminho para o logotipo da empresa

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'center',
//         minHeight: '100vh', // Altura mínima para ocupar toda a tela
//         textAlign: 'center',
//         padding: theme.spacing(2),
//     },
//     logo: {
//         width: 100,
//         height: 100,
//         marginBottom: theme.spacing(2),
//         [theme.breakpoints.down('sm')]: {
//             width: 80,
//             height: 80,
//         },
//     },
//     welcomeText: {
//         marginBottom: theme.spacing(8),
//         [theme.breakpoints.down('sm')]: {
//             marginBottom: theme.spacing(4),
//         },
//     },
//     button: {
//         position: 'relative',
//         marginTop: theme.spacing(2),
//         width: 200,
//         height: 200,
//         borderRadius: '12px',
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'center',
//         padding: theme.spacing(2),
//         overflow: 'hidden', // Para garantir que a imagem não ultrapasse os limites do botão
//         backgroundColor: '#f0f0f0', // Cor padrão dos botões
//         transition: 'background-color 0.3s ease', // Transição suave ao passar o mouse
//         '&:hover': {
//             backgroundColor: '#3f51b5', // Azul ao passar o mouse
//         },
//         [theme.breakpoints.down('sm')]: {
//             width: 170,
//             height: 170,
//             marginTop: theme.spacing(1),
//         },
//     },
//     buttonContainer: {
//         display: 'flex',
//         flexDirection: 'row',
//         gap: theme.spacing(2),
//         marginTop: theme.spacing(4),
//         [theme.breakpoints.down('sm')]: {
//             flexDirection: 'column',
//             alignItems: 'center',
//             marginTop: theme.spacing(2),
//         },
//     },
//     image: {
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: '70%',
//         height: '70%',
//         objectFit: 'cover',
//         opacity: 0.9, // Ajuste a opacidade conforme necessário
//     },
//     text: {
//         position: 'absolute',
//         bottom: theme.spacing(1),
//         zIndex: 1, // Garantir que o texto fique acima da imagem
//         textAlign: 'center',
//         width: '100%', // Para garantir que o texto fique centralizado
//         fontWeight: 'bold',
//     },
// }));

// const HomePage = () => {
//     const classes = useStyles();
//     const navigate = useNavigate();
//     const [showSecondText, setShowSecondText] = useState(false);
//     const [firstTextDone, setFirstTextDone] = useState(false);
//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//     useEffect(() => {
//         const timer = setTimeout(() => {
//             setShowSecondText(true);
//         }, 3000); // Ajuste o tempo conforme necessário, dependendo da duração do primeiro texto

//         return () => clearTimeout(timer);
//     }, []);

//     return (
//         <Container className={classes.root}>
//             <Box
//                 component="img"
//                 src={companyLogo}
//                 alt="Logotipo da Empresa"
//                 className={classes.logo}
//             />
//             <Typography variant={isMobile ? "h4" : "h3"} gutterBottom className={classes.welcomeText}>
//                 Bem-vindo ao Sistema Teórico Web
//             </Typography>
//             {showSecondText && (
//                 <Typography variant={isMobile ? "h6" : "h4"} gutterBottom>
//                     <Typing 
//                         typingDelay={10} 
//                         eraseSpeed={0} 
//                         speed={20} 
//                         text="Por favor, selecione seu tipo de login:" 
//                     />
//                 </Typography>
//             )}
//             <div className={classes.buttonContainer}>
//                 <Button
//                     className={classes.button}
//                     onClick={() => navigate('/login')}
//                 >
//                     <Box
//                         component="img"
//                         src={studentImage}
//                         alt="Aluno"
//                         className={classes.image}
//                     />
//                     <Typography variant="body1" className={classes.text}>Sou Aluno</Typography>
//                 </Button>
//                 <Button
//                     className={classes.button}
//                     onClick={() => navigate('/loginFuncionario')}
//                 >
//                     <Box
//                         component="img"
//                         src={instructorImage}
//                         alt="Instrutor"
//                         className={classes.image}
//                     />
//                     <Typography variant="body1" className={classes.text}>Sou Instrutor</Typography>
//                 </Button>
//             </div>
//         </Container>
//     );
// };

// export default HomePage;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Typography, Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Typing from 'react-typing-effect';
import studentImage from './asset/image/icone4.png';
import instructorImage from './asset/image/icone1.png';
import companyLogo from './asset/image/logo512.png'; // Adicione o caminho para o logotipo da empresa

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center', // Centraliza verticalmente e horizontalmente
        minHeight: '100vh', // Ocupa toda a altura da tela
        textAlign: 'center',
        padding: theme.spacing(2),
    },
    logo: {
        width: 100,
        height: 100,
        marginBottom: theme.spacing(4), // Aumentei o espaçamento inferior para dar mais destaque ao logo
        [theme.breakpoints.down('sm')]: {
            width: 80,
            height: 80,
        },
    },
    welcomeText: {
        marginBottom: theme.spacing(8), // Espaçamento maior para separar bem o texto de boas-vindas dos botões
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
        },
    },
    button: {
        position: 'relative',
        width: 220, // Aumentei a largura e altura dos botões para um layout mais espaçoso
        height: 220,
        borderRadius: '15px', // Bordas um pouco mais arredondadas
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        overflow: 'hidden',
        backgroundColor: '#f0f0f0',
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: '#3f51b5',
        },
        [theme.breakpoints.down('sm')]: {
            width: 180,
            height: 180,
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(4), // Maior espaçamento entre os botões para um layout mais espaçoso
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: theme.spacing(2),
        },
    },
    image: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '65%',
        height: '65%',
        objectFit: 'cover',
        paddingBottom: 10,
        opacity: 0.8, // Reduzi a opacidade para melhorar a visibilidade do texto
    },
    text: {
        position: 'absolute',
        bottom: theme.spacing(2),
        zIndex: 1, // Mantém o texto acima da imagem
        textAlign: 'center',
        width: '100%',
        fontWeight: 'bold',
    },
}));

const HomePage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [showSecondText, setShowSecondText] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSecondText(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Container className={classes.root}>
            <Box
                component="img"
                src={companyLogo}
                alt="Logotipo da Empresa"
                className={classes.logo}
            />
            <Typography variant={isMobile ? "h4" : "h3"} gutterBottom className={classes.welcomeText}>
                Bem-vindo ao Sistema Teórico Web
            </Typography>
            {showSecondText && (
                <Typography variant={isMobile ? "h6" : "h4"} gutterBottom>
                    <Typing 
                        typingDelay={10} 
                        eraseSpeed={0} 
                        speed={20} 
                        text="Por favor, selecione seu tipo de login:" 
                    />
                </Typography>
            )}
            <div className={classes.buttonContainer}>
                <Button
                    className={classes.button}
                    onClick={() => navigate('/login')}
                >
                    <Box
                        component="img"
                        src={studentImage}
                        alt="Aluno"
                        className={classes.image}
                    />
                    <Typography variant="body1" className={classes.text}>Sou Aluno</Typography>
                </Button>
                <Button
                    className={classes.button}
                    onClick={() => navigate('/loginFuncionario')}
                >
                    <Box
                        component="img"
                        src={instructorImage}
                        alt="Instrutor"
                        className={classes.image}
                    />
                    <Typography variant="body1" className={classes.text}>Sou Instrutor</Typography>
                </Button>
            </div>
        </Container>
    );
};

export default HomePage;
