// import React, { useEffect, useState, useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import './AulasPage.css';
// import { formatISO, subHours } from 'date-fns';
// import { AppContext } from '../context/AppContext';
// import { getAulasInstrutor, getBiometriaInstrutor } from '../services/apiService';
// import CryptoJS from 'crypto-js';
// import {
//     Box,
//     Button,
//     Card,
//     CardContent,
//     makeStyles,
//     Container,
//     Grid,
//     Typography,
//     Divider,
//     Dialog,
//     DialogTitle,
//     DialogContent,
//     DialogActions,
// } from '@material-ui/core';

// const useStyles = makeStyles((theme) => ({
//     nomeInstrutor: {
//         fontSize: '1.5rem',
//         fontWeight: "bolder",
//         textAlign: "left",
//         flexGrow: 1,
//         [theme.breakpoints.down('xs')]: {
//             fontSize: '1.2rem',
//         },
//     },
//     captureButton: {
//         margin: "1.5rem auto 0",
//         display: "block",
//         backgroundColor: 'blue',
//         color: 'white',
//         '&:hover': {
//             backgroundColor: 'green',
//         },
//     },
//     captureButtonDisabled: {
//         backgroundColor: 'gray',
//         color: 'white',
//         '&:hover': {
//             backgroundColor: 'black',
//         },
//     },
//     entrarButton: {
//         maxWidth: "170px",
//         marginLeft: theme.spacing(2),
//         backgroundColor: 'lightgray',
//         color: 'darkgray',
//         '&:hover': {
//             backgroundColor: 'gray',
//         },
//         '&.enabled': {
//             backgroundColor: 'purple',
//             color: 'white',
//             '&:hover': {
//                 backgroundColor: 'darkorchid',
//             },
//         },
//     },
//     cardContainer: {
//         position: 'relative',
//     },
//     headerContainer: {
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "space-between",
//     },
// }));

// const AulasPageFuncionario = () => {
//     const classes = useStyles();
//     const [aulas, setAulas] = useState([]);
//     const [statusEntrada, setStatusEntrada] = useState({});
//     const [statusSaida, setStatusSaida] = useState({});
//     const [modalOpen, setModalOpen] = useState(false);
//     const [modalMessage, setModalMessage] = useState('');
//     const navigate = useNavigate();
//     const { setCodAulaTeorica, setCodAluno } = useContext(AppContext);

//     // Função para descriptografar o CPF
// const decryptData = (ciphertext, secretKey) => {
//     const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
//     return bytes.toString(CryptoJS.enc.Utf8);
//   };
  
//   // Recupera e descriptografa o CPF do sessionStorage
//   const getDecryptedCPF = () => {
//     const encryptedCPF = sessionStorage.getItem('cod');
//     if (encryptedCPF) {
//       const secretKey = process.env.REACT_APP_ENCRYPTION_KEY; // Acessa a chave do .env
//       const decryptedCPF = decryptData(encryptedCPF, secretKey);
//       return decryptedCPF;
//     }
//     return null; // Caso não haja CPF armazenado
//   };

//     useEffect(() => {
//         const fetchAulas = async () => {
//             const token = sessionStorage.getItem('cod2');
//             const cpf = getDecryptedCPF();
//             if (!token || !cpf) {
//                 alert('Usuário não autenticado!');
//                 navigate('/');
//                 return;
//             }

//             const now = new Date();
//             const dataInicio = formatISO(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 4, 0, 0), { representation: 'complete' });
//             const dataFim = formatISO(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59), { representation: 'complete' });

//             try {
//                 const response = await getAulasInstrutor(cpf, dataInicio, dataFim);
//                 setAulas(response);

//                 response.forEach(async (aula) => {
//                     const status = await getBiometriaInstrutor(aula.codAula, cpf);
//                     const entrada = status.find(s => s.etapa === 1);
//                     const saida = status.find(s => s.etapa === 2);
//                     if (entrada && entrada.dataHoraColeta) {
//                         setStatusEntrada(prevStatus => ({
//                             ...prevStatus,
//                             [aula.codAula]: { text: 'Realizada às ' + formatDate(new Date(entrada.dataHoraColeta)), color: 'green' }
//                         }));
//                     } else {
//                         setStatusEntrada(prevStatus => ({
//                             ...prevStatus,
//                             [aula.codAula]: { text: 'Entrada não realizada', color: 'red' }
//                         }));
//                     }
//                     if (saida && saida.dataHoraColeta) {
//                         setStatusSaida(prevStatus => ({
//                             ...prevStatus,
//                             [aula.codAula]: { text: 'Realizada às ' + formatDate(new Date(saida.dataHoraColeta)), color: 'green' }
//                         }));
//                     } else {
//                         setStatusSaida(prevStatus => ({
//                             ...prevStatus,
//                             [aula.codAula]: { text: 'Saída não realizada', color: 'red' }
//                         }));
//                     }
//                 });
//             } catch (error) {
//                 console.error('Erro ao buscar aulas:', error);
//                 alert('Erro ao buscar aulas');
//             }
//         };

//         fetchAulas();
//     }, [navigate]);

//     const formatDate = (date) => {
//         const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
//         return date.toLocaleTimeString('pt-BR', options);
//     };

//     const formatDateTime = (dateString) => {
//         const options = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
//         return new Date(dateString).toLocaleString('pt-BR', options);
//     };

//     const isCaptureAllowed = (aula) => {
//         const now = new Date();
//         const aulaInicio = new Date(aula.dataHoraAulaInicio);
//         const aulaFim = new Date(aulaInicio.getTime() + aula.qtdAula * 60 * 50 * 1000);

//         const startCapture = new Date(aulaInicio.getTime() - 27 * 60 * 1000);
//         const endCapture = new Date(aulaFim.getTime() - 30 * 1000);
//         const startPostAulaCapture = aulaFim;
//         const endPostAulaCapture = new Date(aulaFim.getTime() + 39 * 60 * 1000 + 59 * 1000);

//         return (now >= startCapture && now <= endCapture) || (now >= startPostAulaCapture && now <= endPostAulaCapture);
//     };

//     const handleCapture = (aula) => {
//         if (isCaptureAllowed(aula)) {
//             setCodAulaTeorica(aula.codAula);
//             setCodAluno(aula.codInstrutor);
//             navigate('/capture-funcionario');
//         } else {
//             setModalMessage('A Captura de entrada é permitida apenas a partir de 30 minutos antes do início da aula e até 15 minutos após o início da aula. A captura de saída é permitida apenas após o término da aula e até 40 minutos depois.');
//             setModalOpen(true);
//         }
//     };

//     const handleCloseModal = () => {
//         setModalOpen(false);
//     };

//     const handleEnterClass = (aula) => {
//         if (aula && aula.linkAula) {
//             window.open(aula.linkAula, "_blank");
//             // //console.log(`Entering class: ${aula.linkAula}`);
//         } else {
//             // console.error("Link da aula não disponível");
//         }
//     };

//     return (
//         <Container style={{ paddingTop: '2rem' }} >
//             <Typography variant='h3' style={{ textAlign: 'center' }} component='h1'  >
//                 Lista de Aulas - Instrutor
//             </Typography>
//             <div>
//                 {aulas.length > 0 ? (
//                     aulas.map((aula, index) => (
//                         <Box m={4} key={index} fontSize="1rem">
//                             <Card className={classes.cardContainer} style={{ maxWidth: "550px", margin: "1rem auto" }} >
//                                 <CardContent>
//                                     <div className={classes.headerContainer}>
//                                         <Typography className={classes.nomeInstrutor} component="h2">
//                                             {aula.nomeInstrutor.trim()}
//                                         </Typography>
//                                         {/* <Button
//                                             variant="contained"
//                                             size="small"
//                                             className={`${classes.entrarButton} ${statusEntrada[aula.codAula]?.text.includes('Realizada') ? 'enabled' : ''}`}
//                                             onClick={() => handleEnterClass(aula)}
//                                             disabled={!statusEntrada[aula.codAula]?.text.includes('Realizada')}
//                                         >
//                                             Entrar na Aula
//                                         </Button> */}
//                                     </div>
//                                     <Typography style={{ textAlign: "center", marginBottom: "0.8rem" }} variant="body1" component="h3" >
//                                         Instrutor: {aula.nomeInstrutor}
//                                     </Typography>
//                                     <Grid container spacing={1} style={{ margin: "0.3rem 0" }} >
//                                         <Grid item xs={12} sm={6} style={{ padding: "0" }} >
//                                             <Typography component="p" >
//                                                 Início: {formatDateTime(aula.dataHoraAulaInicio)} 
//                                             </Typography>
//                                         </Grid>
//                                         <Grid item xs={12} sm={6} style={{ padding: "0" }} >
//                                             <Typography component="p" >
//                                                 Término: {formatDateTime(aula.dataHoraAulaFim)}
//                                             </Typography>
//                                         </Grid>
//                                         <Grid item xs={12}  >
//                                             <Divider style={{ alignItems: "center", margin: "auto" }} />
//                                         </Grid>
//                                         <Grid item xs={12} sm={6} style={{ padding: "0" }} >
//                                             <Typography component="p" >
//                                                 Quantidade de Aulas: {aula.qtdAula}
//                                             </Typography>
//                                         </Grid>
//                                         <Grid item xs={12} sm={6} style={{ padding: "0" }} >
//                                             <Typography component="p" >
//                                                 Código da Aula: {aula.codAula}
//                                             </Typography>
//                                         </Grid>
//                                         <Grid item xs={12}  >
//                                             <Divider style={{ alignItems: "center", margin: "auto" }} />
//                                         </Grid>
//                                         <Grid item xs={12} sm={6} style={{ padding: "0", color: statusEntrada[aula.codAula]?.color }} >
//                                             <Typography component="p" >
//                                                 Entrada: {statusEntrada[aula.codAula]?.text}
//                                             </Typography>
//                                         </Grid>
//                                         <Grid item xs={12} sm={6} style={{ padding: "0", color: statusSaida[aula.codAula]?.color }} >
//                                             <Typography component="p" >
//                                                 Saída: {statusSaida[aula.codAula]?.text}
//                                             </Typography>
//                                         </Grid>
//                                     </Grid>
//                                     <Button
//                                         variant="contained"
//                                         size="large"
//                                         fullWidth
//                                         className={`${classes.captureButton} ${!isCaptureAllowed(aula) ? classes.captureButtonDisabled : ''}`}
//                                         onClick={() => handleCapture(aula)}
//                                     >
//                                         Capturar Foto
//                                     </Button>
//                                 </CardContent>
//                             </Card>
//                         </Box>
//                     ))
//                 ) : (
//                     <Typography variant='h5' component='p' style={{ textAlign: "center", margin: "2rem auto" }} >
//                         Não foram encontradas aulas para o CPF cadastrado!
//                     </Typography>
//                 )}
//             </div>
//             <Dialog open={modalOpen} onClose={handleCloseModal}>
//                 <DialogTitle>Aviso</DialogTitle>
//                 <DialogContent>
//                     <Typography>{modalMessage}</Typography>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handleCloseModal} color="primary">Fechar</Button>
//                 </DialogActions>
//             </Dialog>
//         </Container>
//     );
// };

// export default AulasPageFuncionario;
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatISO } from 'date-fns';
import { AppContext } from '../context/AppContext';
import { getAulasInstrutor, getBiometriaInstrutor } from '../services/apiService';
import CryptoJS from 'crypto-js';
import {
    Box,
    Button,
    Card,
    CardContent,
    makeStyles,
    Container,
    Grid,
    Typography,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    nomeInstrutor: {
        fontSize: '1.5rem',
        fontWeight: "bolder",
        textAlign: "left",
        flexGrow: 1,
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem',
        },
    },
    captureButton: {
        margin: "1.5rem auto 0",
        display: "block",
        backgroundColor: 'blue',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green',
        },
    },
    captureButtonDisabled: {
        backgroundColor: 'gray',
        color: 'white',
        '&:hover': {
            backgroundColor: 'black',
        },
    },
    cardContainer: {
        position: 'relative',
        border: '1px solid #ccc',
        borderRadius: '10px',
        padding: '20px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '600px',
        margin: '1rem auto',
    },
    headerContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
}));

const AulasPageFuncionario = () => {
    const classes = useStyles();
    const [aulas, setAulas] = useState([]);
    const [statusEntrada, setStatusEntrada] = useState({});
    const [statusSaida, setStatusSaida] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [loading, setLoading] = useState(true); // Estado de carregamento
    const navigate = useNavigate();
    const { setCodAulaTeorica, setCodAluno } = useContext(AppContext);

    // Função para descriptografar o CPF
    const decryptData = (ciphertext, secretKey) => {
        const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    // Recupera e descriptografa o CPF do sessionStorage
    const getDecryptedCPF = () => {
        const encryptedCPF = sessionStorage.getItem('cod');
        if (encryptedCPF) {
            const secretKey = process.env.REACT_APP_ENCRYPTION_KEY; // Acessa a chave do .env
            const decryptedCPF = decryptData(encryptedCPF, secretKey);
            return decryptedCPF;
        }
        return null; // Caso não haja CPF armazenado
    };

    useEffect(() => {
        const fetchAulas = async () => {
            const token = sessionStorage.getItem('cod2');
            const cpf = getDecryptedCPF();
            if (!token || !cpf) {
                alert('Usuário não autenticado!');
                navigate('/');
                return;
            }

            const now = new Date();
            const dataInicio = formatISO(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 4, 0, 0), { representation: 'complete' });
            const dataFim = formatISO(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59), { representation: 'complete' });

            try {
                const response = await getAulasInstrutor(cpf, dataInicio, dataFim);
                setAulas(response);

                for (const aula of response) {
                    const status = await getBiometriaInstrutor(aula.codAula, cpf);
                    const entrada = status.find(s => s.etapa === 1);
                    const saida = status.find(s => s.etapa === 2);
                    if (entrada && entrada.dataHoraColeta) {
                        setStatusEntrada(prevStatus => ({
                            ...prevStatus,
                            [aula.codAula]: { text: 'Realizada às ' + formatDate(new Date(entrada.dataHoraColeta)), color: 'green' }
                        }));
                    } else {
                        setStatusEntrada(prevStatus => ({
                            ...prevStatus,
                            [aula.codAula]: { text: 'Entrada não realizada', color: 'red' }
                        }));
                    }
                    if (saida && saida.dataHoraColeta) {
                        setStatusSaida(prevStatus => ({
                            ...prevStatus,
                            [aula.codAula]: { text: 'Realizada às ' + formatDate(new Date(saida.dataHoraColeta)), color: 'green' }
                        }));
                    } else {
                        setStatusSaida(prevStatus => ({
                            ...prevStatus,
                            [aula.codAula]: { text: 'Saída não realizada', color: 'red' }
                        }));
                    }
                }

                setLoading(false); // Desativa o carregamento após buscar os dados
            } catch (error) {
                console.error('Erro ao buscar aulas:', error);
                alert('Erro ao buscar aulas');
                setLoading(false); // Desativa o carregamento em caso de erro
            }
        };

        fetchAulas();
    }, [navigate]);

    const formatDate = (date) => {
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return date.toLocaleTimeString('pt-BR', options);
    };

    const formatDateTime = (dateString) => {
        const options = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return new Date(dateString).toLocaleString('pt-BR', options);
    };

    const isCaptureAllowed = (aula) => {
        const now = new Date();
        const aulaInicio = new Date(aula.dataHoraAulaInicio);
        const aulaFim = new Date(aulaInicio.getTime() + aula.qtdAula * 60 * 50 * 1000);

        const startCapture = new Date(aulaInicio.getTime() - 27 * 60 * 1000);
        const endCapture = new Date(aulaFim.getTime() - 30 * 1000);
        const startPostAulaCapture = aulaFim;
        const endPostAulaCapture = new Date(aulaFim.getTime() + 39 * 60 * 1000 + 59 * 1000);

        return (now >= startCapture && now <= endCapture) || (now >= startPostAulaCapture && now <= endPostAulaCapture);
    };

    const handleCapture = (aula) => {
        if (isCaptureAllowed(aula)) {
            setCodAulaTeorica(aula.codAula);
            setCodAluno(aula.codInstrutor);
            navigate('/capture-funcionario');
        } else {
            setModalMessage('A Captura de entrada é permitida apenas a partir de 30 minutos antes do início da aula e até 15 minutos após o início da aula. A captura de saída é permitida apenas após o término da aula e até 40 minutos depois.');
            setModalOpen(true);
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <Container style={{ paddingTop: '2rem' }} >
            {loading ? (
                <div className={classes.loadingContainer}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <Typography variant='h3' style={{ textAlign: 'center' }} component='h1'>
                        Lista de Aulas - Instrutor
                    </Typography>
                    <div>
                        {aulas.length > 0 ? (
                            aulas.map((aula, index) => (
                                <Box m={4} key={index} fontSize="1rem">
                                    <Card className={classes.cardContainer}>
                                        <CardContent>
                                            <div className={classes.headerContainer}>
                                                <Typography className={classes.nomeInstrutor} component="h2">
                                                    {aula.nomeInstrutor.trim()}
                                                </Typography>
                                            </div>
                                            <Grid container spacing={1} style={{ margin: "0.3rem 0" }}>
                                                <Grid item xs={12} sm={6} style={{ padding: "0" }}>
                                                    <Typography component="p">
                                                        Início: {formatDateTime(aula.dataHoraAulaInicio)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} style={{ padding: "0" }}>
                                                    <Typography component="p">
                                                        Término: {formatDateTime(aula.dataHoraAulaFim)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider style={{ alignItems: "center", margin: "auto" }} />
                                                </Grid>
                                                <Grid item xs={12} sm={6} style={{ padding: "0" }}>
                                                    <Typography component="p">
                                                        Quantidade de Aulas: {aula.qtdAula}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} style={{ padding: "0" }}>
                                                    <Typography component="p">
                                                        Código da Aula: {aula.codAula}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider style={{ alignItems: "center", margin: "auto" }} />
                                                </Grid>
                                                <Grid item xs={12} sm={6} style={{ padding: "0", color: statusEntrada[aula.codAula]?.color }}>
                                                    <Typography component="p">
                                                        Entrada: {statusEntrada[aula.codAula]?.text}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} style={{ padding: "0", color: statusSaida[aula.codAula]?.color }}>
                                                    <Typography component="p">
                                                        Saída: {statusSaida[aula.codAula]?.text}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                fullWidth
                                                className={`${classes.captureButton} ${!isCaptureAllowed(aula) ? classes.captureButtonDisabled : ''}`}
                                                onClick={() => handleCapture(aula)}
                                            >
                                                Capturar Foto
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </Box>
                            ))
                        ) : (
                            <Typography variant='h5' component='p' style={{ textAlign: "center", margin: "2rem auto" }}>
                                Não foram encontradas aulas para o CPF cadastrado!
                            </Typography>
                        )}
                    </div>
                    <Dialog open={modalOpen} onClose={handleCloseModal}>
                        <DialogTitle>Aviso</DialogTitle>
                        <DialogContent>
                            <Typography>{modalMessage}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseModal} color="primary">Fechar</Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </Container>
    );
};

export default AulasPageFuncionario;
