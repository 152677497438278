import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Container, Typography, makeStyles } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
    },
    button: {
        marginTop: theme.spacing(2),
    },
    icon: {
        fontSize: '4rem',
        color: 'red',
        marginBottom: theme.spacing(2),
    },
}));

const BiometriaFailureFuncionarioPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { message } = location.state || { message: "Erro ao enviar a imagem." };
    const [countdown, setCountdown] = useState(10);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 1) {
                    navigate('/aulas-funcionario');
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [navigate]);

    return (
        <Container className={classes.root}>
            <HighlightOffIcon className={classes.icon} />
            <Typography variant="h4" gutterBottom>
                Falha
            </Typography>
            <Typography variant="body1">
                {message}
            </Typography>
            <Typography variant="body1">
                Você será redirecionado para a lista de aulas em {countdown} segundos.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => navigate('/aulas-funcionario')}
            >
                Voltar para a Lista de Aulas
            </Button>
        </Container>
    );
};

export default BiometriaFailureFuncionarioPage;

    