// import React, { createContext, useState } from 'react';

// export const AppContext = createContext();

// export const AppProvider = ({ children }) => {
//   const [codAulaTeorica, setCodAulaTeorica] = useState(null);
//   const [codAluno, setCodAluno] = useState(null);
//   const [capturedImage, setCapturedImage] = useState(null);

//   return (
//     <AppContext.Provider value={{ codAulaTeorica, setCodAulaTeorica, codAluno, setCodAluno, capturedImage, setCapturedImage }}>
//       {children}
//     </AppContext.Provider>
//   );
// };
import React, { createContext, useState, useEffect } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [codAulaTeorica, setCodAulaTeorica] = useState(() => sessionStorage.getItem('codAulaTeorica') || null);
  const [codAluno, setCodAluno] = useState(() => sessionStorage.getItem('codAluno') || null);
  const [capturedImage, setCapturedImage] = useState(() => sessionStorage.getItem('capturedImage') || null);

  // Salvando o estado no sessionStorage quando eles mudam
  useEffect(() => {
    sessionStorage.setItem('codAulaTeorica', codAulaTeorica);
  }, [codAulaTeorica]);

  useEffect(() => {
    sessionStorage.setItem('codAluno', codAluno);
  }, [codAluno]);

  useEffect(() => {
    sessionStorage.setItem('capturedImage', capturedImage);
  }, [capturedImage]);

  return (
    <AppContext.Provider
      value={{
        codAulaTeorica,
        setCodAulaTeorica,
        codAluno,
        setCodAluno,
        capturedImage,
        setCapturedImage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
